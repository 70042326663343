<template>
  <!-- 酒店的票面 -->
  <div class="hotelList">
    <div v-for="(ticketInfo,i) in showList" :key="i">
      <van-row
        v-if="ticketInfo.ratePlans.length==1"
        type="flex"
        align="center"
        justify="space-between"
        class="room-item"
      >
        <van-row
          type="flex"
          align="end"
          justify="space-between"
          style="max-width:calc(100% - 50px)"
        >
          <van-col>
            <van-image
              class="dtail-img"
              :error-icon="'./hotelError.png'"
              :src="ticketInfo.imageUrl"
              width="5rem"
            />
          </van-col>
          <van-col>
            <h5 class="list-title">
              <el-tooltip
                class="item"
                effect="dark"
                :content="ticketInfo.name"
                placement="top"
                :hide-after="3000"
              >
                <span class="van-ellipsis over-text">{{ticketInfo.name}}</span>
              </el-tooltip>
              <van-tag v-if="ticketInfo.ratePlans[0].isOk==0" class="tag" plain type="success">全员合规</van-tag>
              <el-tooltip
                v-else-if="ticketInfo.ratePlans[0].isOk<standard.length"
                class="item"
                effect="dark"
                placement="top"
                :content="'超标人员：'+ticketInfo.ratePlans[0].excessList.join(',')"
              >
                <van-tag class="tag" plain type="warning">部分超标</van-tag>
              </el-tooltip>
              <van-tag v-else class="tag" plain type="danger">全员超标</van-tag>
            </h5>
            <van-row class="list-text">
              <span>{{ticketInfo.ratePlans[0].breakfast||'不含早'}}</span>
              <span
                v-if="ticketInfo.hasOwnProperty('area')&&ticketInfo.area!=''"
              >&nbsp;|&nbsp;{{ticketInfo.area}}m²</span>
              <span
                v-if="ticketInfo.hasOwnProperty('windows')&&ticketInfo.windows!=''"
              >&nbsp;|&nbsp;{{ticketInfo.windows}}</span>
              <!-- <span v-if="ticketInfo.ratePlans[0].paymentType">&nbsp;|&nbsp;{{ticketInfo.ratePlans[0].paymentType}}</span> -->
            </van-row>
            <van-row class="list-text">
              <span
                :class="ticketInfo.ratePlans[0].refundType=='不可取消'?'red':'blue'"
              >{{ticketInfo.ratePlans[0].refundType}}</span>
            </van-row>
          </van-col>
        </van-row>
        <van-col @click="chooseHotel(ticketInfo)" :class="'pay-btn '+(ticketInfo.ratePlans[0].isOk>=standard.length&&banCorp.includes(corp)?'disable-btn':'')">
          <div
            :class="'double-btn '+(ticketInfo.ratePlans[0].paymentType=='预付'?'red':'warning')+'-border'"
          >
            <van-button
              :type="ticketInfo.ratePlans[0].paymentType=='预付'?'danger':'warning'"
              size="mini"
              style="padding:0 8px"
            >{{ticketInfo.ratePlans[0].paymentType=='预付'?'预付':'到付'}}</van-button>
            <span>￥{{ticketInfo.ratePlans[0].averageRate}}</span>
          </div>
        </van-col>
      </van-row>
      <!-- 一类床型多价格 -->
      <van-row v-else type="flex" align="center" justify="space-between" class="room-item">
        <van-row type="flex" align="end" justify="space-between">
          <van-col>
            <van-image
              class="dtail-img"
              :error-icon="'./hotelError.png'"
              :src="ticketInfo.imageUrl"
              width="5rem"
            />
          </van-col>
          <van-col>
            <div class="display:flex">
              <h5 class="list-title">
                <span
                  class="van-ellipsis"
                  style="width:calc(100vw - 120px);max-width:auto"
                >{{ticketInfo.name}}</span>
              </h5>
              <van-row class="list-text">
                <span>{{ticketInfo.windows||'无窗'}}</span>
                <span v-if="ticketInfo.floor">&nbsp;|&nbsp;{{ticketInfo.floor}}层</span>
                <span
                  v-if="ticketInfo.hasOwnProperty('area')&&ticketInfo.area!=''"
                >&nbsp;|&nbsp;{{ticketInfo.area}}m²</span>
                <!-- <div class="gap"></div> -->
              </van-row>
            </div>
            <van-row
              v-for="(bed,index2) in ticketInfo.ratePlans"
              :key="index2"
              class="list-text child-bed"
              type="flex"
              align="center"
              justify="space-between"
            >
              <van-col>
                <div class="child-tag">
                  <van-tag v-if="bed.isOk==0" class="tag" plain type="success">全员合规</van-tag>
                  <el-tooltip
                    v-else-if="bed.isOk<standard.length"
                    class="item"
                    effect="dark"
                    placement="top"
                    :content="'超标人员：'+bed.excessList.join(',')"
                  >
                    <van-tag class="tag" plain type="warning">部分超标</van-tag>
                  </el-tooltip>
                  <van-tag v-else class="tag" plain type="danger">全员超标</van-tag>
                </div>
                {{bed.breakfast}}
                &nbsp;|&nbsp;
                <span
                  :class="bed.refundType=='不可取消'?'red':'blue'"
                >{{bed.refundType}}</span>
              </van-col>
              <van-col @click="chooseHotel(ticketInfo,bed)" :class="'pay-btn '+(bed.isOk>=standard.length&&banCorp.includes(corp)?'disable-btn':'')">
                <div :class="'double-btn '+(bed.paymentType=='预付'?'red':'warning')+'-border'">
                  <van-button
                    :type="bed.paymentType=='预付'?'danger':'warning'"
                    size="mini"
                    style="padding:0 8px"
                  >{{bed.paymentType=='预付'?'预付':'到付'}}</van-button>
                  <span>￥{{bed.averageRate}}</span>
                </div>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-row>
    </div>
    <div class="gap"></div>
  </div>
</template>

<script>
export default {
  name: "HotelItem",
  props: {
    corp: String,
    list: Array,
    standard: Array,
  },
  computed: {
    showList: function () {
      this.list.forEach((element) => {
        element.ratePlans.forEach((child) => {
          child.isOk = 0;
          child.excessList = [];
          let maxPrice = 0;
          child.nightlyRates.forEach((e) => {
            maxPrice = Math.max(maxPrice, parseInt(e.price));
          });
          this.standard.forEach((peo) => {
            if (parseInt(peo.hotelMaxPrice) < maxPrice) {
              child.isOk++;
              child.excessList.push(peo.userName);
            }
          });
        });
      });
      return this.list;
    },
  },
    data() {
    return {
      banCorp: ['行旅'], // 超标不可订
    };
  },
  methods: {
    chooseHotel(room, child = false) {
      let check = child || room.ratePlans[0];
      if (check.isOk >= this.standard.length && this.banCorp.includes(this.corp)) return this.$toast.fail('全员超额请尝试其他房型');
      if (child) room.ratePlans = [child];
      this.$emit("childClick", room);
    },
  },
};
</script>

<style lang="less" scoped>
.pay-btn {
  align-self: center;
}
.disable-btn {
  filter: grayscale(1);
  opacity: 0.66;
}
.hotelList {
  padding: 0 16px;
  background-color: #fff;
}
.dtail-img {
  min-width: 80px;
  margin-right: 8px;
}
.double-btn {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  border-radius: 4px;
}
.warning-border {
  color: #ff976a;
  border: 1px solid #ff976a;
}
.red-border {
  color: #f00;
  border: 1px solid #f00;
}
.room-item {
  padding: 6px 0;
  border-bottom: 1px solid #eee;
}
.tag {
  font-size: 10px;
}
.list-title {
  padding-top: 0;
  display: flex;
  align-items: center;
}
.over-text {
  max-width: calc(100vw - 230px);
  padding-right: 2px;
  display: inline-block;
}
</style>
<style lang="less">
.van-image__error {
  position: relative;
}
.hotelList {
  .van-icon__image {
    width: 100%;
    height: auto;
  }
}
.child-bed {
  border-top: 1px solid #ebedf0;
  margin-top: 0.6rem;
  padding-top: 0.6rem;
}
.child-tag {
  margin-bottom: 0.4rem;
}
</style>