<template>
  <div class="detail-hotel">
    <div style="position:relative">
      <van-image
        @click.stop="$emit('preview')"
        width="100vw"
        :src="(detail.images&&(0 in detail.images))?detail.images[0].bigImageUrl:hotelticket.hotelPic"
        class="img-cont"
      />
      <div class="banner-title">
        <p class="banner-cont">
          <van-icon style="font-size:14px;padding-right:2px" name="photo-o" />
          {{(detail.images||[]).length}}张
        </p>
      </div>
    </div>
    <h4 class="title-font" is-link @click="cons">
      {{hotelticket.chineseName}}
      <span style="width:4px"></span>
      <Logo :corp="hotelticket.corp" plain />
    </h4>
    <van-cell class="auto-cell" size="large" title-class="detailtext">
      <template #title>
        <a
          class="list-text-big"
          :href="'https://uri.amap.com/marker?position='+hotelticket.longitude+','+hotelticket.latitude+'&name='+hotelticket.chineseName+'&callnative=1'"
        >地址：{{hotelticket.address}}</a>
      </template>
      <template #default>
        <a
          :href="'https://uri.amap.com/marker?position='+hotelticket.longitude+','+hotelticket.latitude+'&name='+hotelticket.chineseName+'&callnative=1'"
        >
          <van-icon name="location" class="van-cell__right-icon icon-btn blue" />
        </a>
        <a
          :href="'tel:'+hotelticket.tel"
          v-if="/(\d{2,5}-\d{7,8}$)|(^13\d{9}$)|(^15\d{9}$)|(^18\d{9}$)/.test( hotelticket.tel.trim())"
        >
          <van-icon name="phone" class="van-cell__right-icon icon-btn blue" />
        </a>
        <van-icon
          v-else
          name="phone"
          class="van-cell__right-icon icon-btn blue"
          @click="openPhone(hotelticket.tel)"
        />
      </template>
    </van-cell>

    <!-- 弹出层 -->
    <van-action-sheet v-model="show" title="酒店信息">
      <div class="content">
        <van-cell
          v-if="detail.introEditorCn"
          title="简介"
          arrow-direction="down"
          @click="introEditorCn=!introEditorCn"
          :is-link="(detail.introEditorCn||[]).length>30"
          :value="detail.introEditorCn.length>30?'展开':''"
        />
        <span v-if="detail.introEditorCn" :class="introEditorCn?'close':''">{{detail.introEditorCn}}</span>
        <van-cell
          v-if="detail.generalAmenitiesCn"
          title="便利设施"
          arrow-direction="down"
          @click="generalAmenitiesCn=!generalAmenitiesCn"
          :is-link="(detail.generalAmenitiesCn||[]).length>30"
          :value="detail.generalAmenitiesCn.length>30?'展开':''"
        />
        <span
          v-if="detail.generalAmenitiesCn"
          :class="generalAmenitiesCn?'close':''"
        >{{detail.generalAmenitiesCn}}</span>
        <van-cell
          v-if="detail.meetingAmenities"
          title="会议设施"
          arrow-direction="down"
          @click="meetingAmenities=!meetingAmenities"
          :is-link="(detail.meetingAmenities||[]).length>30"
          :value="detail.meetingAmenities.length>30?'展开':''"
        />
        <span
          v-if="detail.meetingAmenities"
          :class="meetingAmenities?'close':''"
        >{{detail.meetingAmenities}}</span>
        <van-cell
          v-if="detail.recreationAmenities"
          title="休闲设施"
          arrow-direction="down"
          @click="recreationAmenities=!recreationAmenities"
          :is-link="(detail.recreationAmenities||[]).length>30"
          :value="detail.recreationAmenities.length>30?'展开':''"
        />
        <span
          v-if="detail.recreationAmenities"
          :class="recreationAmenities?'close':''"
        >{{detail.recreationAmenities}}</span>
        <van-cell
          v-if="detail.traffic"
          title="交通设施"
          arrow-direction="down"
          @click="traffic=!traffic"
          :is-link="detail.traffic.length>30"
          :value="detail.traffic.length>30?'展开':''"
        />
        <span v-if="detail.traffic" :class="traffic?'close':''">{{detail.traffic}}</span>
        <van-cell
          v-if="detail.serviceAmenities"
          title="服务"
          arrow-direction="down"
          @click="serviceAmenities=!serviceAmenities"
          :is-link="detail.serviceAmenities.length>30"
          :value="detail.serviceAmenities.length>30?'展开':''"
        />
        <span
          v-if="detail.serviceAmenities"
          :class="serviceAmenities?'close':''"
        >{{detail.serviceAmenities}}</span>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
export default {
  name: "DetailHotelHead",
  components: { Logo },
  data() {
    return {
      show: false,
      activeNames: [],
      traffic: true, //交通设施折叠开关
      introEditorCn: true,
      recreationAmenities: true,
      meetingAmenities: true,
      serviceAmenities: true,
      generalAmenitiesCn: true,
    };
  },
  props: ["hotelticket", "detail"],
  methods: {
    cons() {
      if (
        this.detail.introEditorCn ||
        this.detail.generalAmenitiesCn ||
        this.detail.meetingAmenities ||
        this.detail.recreationAmenities ||
        this.detail.traffic ||
        this.detail.serviceAmenities
      )
        this.show = true;
    },
    openPhone(phone) {
      this.$dialog.alert({
        title: "供应商电话",
        message: phone,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.img-cont {
  max-height: 50vh;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
}
.banner-title {
  padding: 0.4rem;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  z-index: 4;
  color: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 10px;
  box-sizing: border-box;
  p {
    margin: 0;
    transform: scale(0.9) translateX(-5%);
  }
  .banner-cont {
    float: right;
    display: flex;
    right: 5%;
    bottom: 0.4rem;
    align-items: center;
  }
}
.title-font {
  font-size: 18px;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: space-between;
  background-color: #fff;
  padding: 14px 16px 0;
}
.icon-btn {
  padding: 5px 5px 5px 5px;
  font-size: 20px;
}
.comment {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  font-size: 16px;
  padding-top: 10px;
  small {
    padding-left: 4px;
    font-size: 12px;
    align-self: flex-end;
    padding-bottom: 5px;
    line-height: 12px;
  }
}
.detailtext {
  line-height: 26px;
  font-size: 14px;
}
.content {
  padding: 16px 16px 90px;
}
h6 {
  margin: 6px 3px;
  padding-bottom: 3px;
}
span {
  transition: all 2s;
  font-size: 14px;
  color: rgb(92, 92, 92);
  width: 97%;
}
.bl {
  color: #2396f1;
}
.van-cell__right-icon {
  margin-left: 5px;
  color: #2396f1;
}
.detailtext {
  line-height: 26px;
  font-size: 14px;
  padding-top: 5px;
}
.blue {
  color: #2396f1;
}
.close {
  width: 97%;
  word-break: break-all;
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
  font-size: 14px;
  color: rgb(92, 92, 92);
}
</style>