<template>
  <van-row class="detail-hotel">
    <DetailHead :hotelticket="hotelticket" :detail="detail" @preview="preview" />
    <div class="gap"></div>

    <van-cell>
      <template slot="title">
        <van-row type="flex" justify="space-between" align="center" style="height: 40px" @click="show_calendar = true">
          <van-col>
            <span class="h4">
              {{hotelOrderInfo.checkInDate|comverTime('MM月DD日')}}
              <small>{{hotelOrderInfo.checkInDate| comverTime('ddd')}}</small>
            </span>
          </van-col>
          <van-col>
            <p class="toptitle">
              —<span class="timecircle">{{hotelOrderInfo|diffdate}}天</span>—
            </p>
          </van-col>
          <van-col>
            <span class="h4">
              {{hotelOrderInfo.departDate|comverTime('MM月DD日')}}
              <small>{{hotelOrderInfo.departDate| comverTime('ddd')}}</small>
            </span>
          </van-col>
        </van-row>
      </template>
    </van-cell>

    <van-empty v-show="!((0 in roomList)||loading)" description="没有找到可预订房型，请稍后再试。" />
    <van-skeleton title avatar :row="2" :loading="loading" avatar-size="90px" avatar-shape="square" class="skeleton">
      <List :list="roomList" :corp="hotelticket.corp" @childClick="chooseHotel($event)" :standard="standard" />
    </van-skeleton>
    <van-skeleton title avatar :row="2" :loading="loading" avatar-size="90px" avatar-shape="square" class="skeleton"></van-skeleton>

    <!--日期选择-->
    <van-calendar v-model="show_calendar" color="#198BDA" confirm-text="确定" type="range" :show-confirm="false" :default-date="[new Date(hotelOrderInfo.checkInDate),new Date(hotelOrderInfo.departDate)]" @confirm="calendarConfirm" />
  </van-row>
</template>

<script>
import { ImagePreview } from "vant";
import { mapGetters } from "vuex";
import { DiffDate } from "@/services/date.js";
import {
  getHotelDetail,
  getHotelRoomDetail,
  getHotelStandard
} from "@/api/hotel";
import List from "./components/HotelItem";
import DetailHead from "./components/DetailHead";
export default {
  name: "DetailHotel",
  computed: {
    ...mapGetters(["hotelOrderInfo", "hotelticket", "personList"])
  },
  components: { List, DetailHead },
  async activated() {
    window.setTitle(this.hotelticket.chineseName);
    this.fetchDetail();
    await this.fetchStandard();
    this.fetchRoom();
  },
  deactivated() {
    this.detail = {} // 退出时清空数据
  },
  data() {
    return {
      show_calendar: false,
      detail: {}, // 详情
      roomList: [], // 房间列表
      standard: [], // 差标
      loading: true
    };
  },
  methods: {
    // 获取酒店详情
    fetchDetail() {
      return new Promise((resolve, reject) => {
        getHotelDetail({
          checkInDate: this.hotelOrderInfo.checkInDate,
          checkOutDate: this.hotelOrderInfo.departDate,
          hotelId: this.hotelticket.id,
          corp: this.hotelticket.corp
        })
          .then(response => {
            this.detail = response.data;
            resolve(response.data);
          })
          .catch(err => {
            reject(null);
          });
      });
    },
    // 获取房间列表
    fetchRoom() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        getHotelRoomDetail({
          checkIn: this.hotelOrderInfo.checkInDate,
          checkOut: this.hotelOrderInfo.departDate,
          hotelNo: this.hotelticket.id,
          cityId: this.hotelticket.cityCode,
          corp: this.hotelticket.corp
        })
          .then(response => {
            this.loading = false;
            let rooms = response.data.rooms||[];
            this.roomList = rooms;
            resolve(rooms);
          })
          .catch(err => {
             this.roomList = []
            this.loading = false;
            reject(null);
          });
      });
    },
    // 获取差标
    fetchStandard() {
      return new Promise((resolve, reject) => {
        getHotelStandard({
          checkIn: this.hotelOrderInfo.checkInDate,
          checkOut: this.hotelOrderInfo.departDate,
          hotelCity: "53",
          travelUsers: this.personList
        })
          .then(response => {
            this.standard = response;
            resolve(response);
          })
          .catch(err => {
            reject(null);
          });
      });
    },
    // 图片预览
    preview() {
      let arr = [];
      this.detail.images.forEach(element => {
        arr.push(element.bigImageUrl);
      });
      ImagePreview(arr);
    },
    // 选择时间
    calendarConfirm(date) {
      this.$store.dispatch("hotel/setHotelOrder", {
        checkInDate: date[0],
        departDate: date[1]
      });
      this.show_calendar = false;
      this.fetchRoom();
    },
    // 选择房间
    chooseHotel(ticketInfo) {
      this.$store.dispatch("hotel/setRoomTicket", ticketInfo);
      this.$router.push({
        path: "/orderHotel"
      });
    }
  },
  filters: {
    // 间隔几日
    diffdate: function (date) {
      return DiffDate(date.checkInDate, date.departDate);
    }
  }
};
</script>

<style lang="less" scoped>
.timecircle {
  border: 1px solid #323233;
  color: #323233;
  font-size: 12px;
  display: inline-block;
  border-radius: 9px;
  padding: 0 10px;
  line-height: 18px;
}
.skeleton {
  background: #fff;
  min-height: 60px;
  padding-bottom: 1rem;
}
</style>